import React from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { ErrorMessage, Field, Form, Formik, FormikFormProps, FormikValues, useFormik, useFormikContext } from "formik";
import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import HelpInfo from "./HelpInfo";
import LoadingButton from "./LoadingButton";
import { deleteSubCenter, logout, setSubCenter, setToken } from "../../shared/services/session-service";
import { getStates } from "../../shared/services/state";
import { authenticateServiceExperts } from "../../shared/services/survey-service";
import { Option } from "../../models/option";
import { HttpRequestError } from "../../models/http-request-error";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCustomer } from "../../shared/services/customer-service";
import { useLocation } from "react-router";
import { SurveyContext } from "../../pages/survey-context";
import { postVisitorTracking } from "../../shared/services/visitor-tracking-service";

const FormHandler = ({ states, setLoading }: { states: Option<number>[], setLoading: any }) => {
  const { setFieldValue, setValues, getFieldProps, getFieldMeta } = useFormikContext();
  const [searchParams] = useSearchParams();
  const context = useContext(SurveyContext);
  const sendVisitorTracking = async (satelliteCode?: string) => {
    await postVisitorTracking({
      companyId: 5,
      pageName: 'Customer Information',
      satelliteCode: satelliteCode
    });
  }
  const fetchCustomer = async (customerId: string) => {
    let versionParam = searchParams.get('version');
    let version = 1;
    if (versionParam) {
      version = parseInt(versionParam);
    }

    let serviceId = searchParams.get('serviceId');
    let source = searchParams.get('source');
    let technicianId = searchParams.get('technicianId') ? Number(searchParams.get('technicianId')) : null;

    setLoading(true);
    const customer = await getCustomer(customerId, Number(serviceId), technicianId, version);
    setLoading(false);
    
    if (version !== 2 && version !== 3) {
      setValues({
        code: customer.subCenter,
        satelliteCode: customer.subCenter,
        respondentCode: customer.customerId,
        technician: customer.primaryTechnician,
        technicianId: null,
        firstName: customer.firstName,
        lastName: customer.lastName,
        streetAddress: customer.addressLine1,
        city: customer.city,
        state: customer.state,
        postalCode: customer.zipCode,
        phoneNumber: customer.homePhone,
        emailAddress: customer.emailAddress,
        serviceId: 0,
        version: 0,
        subscribe: getFieldProps('subscribe').value,
        source: null,
        membershipType: customer.membershipType,
        jobType: customer.jobType,
      })

    } else {
      setValues({
        code: customer.subCenter,
        satelliteCode: customer.subCenter,
        respondentCode: customer.customerId,
        technician: customer.primaryTechnician,
        technicianId: customer.technicianId,
        firstName: customer.firstName,
        lastName: customer.lastName,
        streetAddress: customer.addressLine1,
        city: customer.city,
        state: customer.state,
        postalCode: customer.zipCode,
        phoneNumber: customer.homePhone,
        emailAddress: customer.emailAddress,
        serviceId: serviceId,
        version: version,
        subscribe: getFieldProps('subscribe').value,
        source: source,
        membershipType: customer.membershipType,
        jobType: customer.jobType,
      })
    }

    context.updateSubCenter(customer.subCenter);
    setSubCenter(customer.subCenter);
    await sendVisitorTracking(customer.subCenter)
  }
  useEffect(() => {
    let lookup = searchParams.get('lookup');
    if (lookup) {
      fetchCustomer(lookup);
    } else {
      let customerId = searchParams.get('customer_id');
      if (customerId) {
        fetchCustomer(customerId);
      } else {
        deleteSubCenter();
        sendVisitorTracking();
      }
    }


  }, []);
  return null;
};

const ExpertsForm = () => {
  const swal = withReactContent(Swal);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState<Option<number>[]>([]);
  const fetchStates = async () => {
    const states = await getStates();
    setStates(states);
  }

  useEffect(() => {
    logout();
    fetchStates();
  }, []);

  const displayHelp = () => {
    swal.fire({
      title: (
        <div className="text-lg text-sky-600">
          How to find your Customer Code
        </div>
      ),
      confirmButtonText: "Close",
      confirmButtonColor: "#0284C7",
      html: (
        <div>
          <HelpInfo />
        </div>
      ),
    });
  };

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    try {
      const response = await authenticateServiceExperts({
        code: values.code,
        satelliteCode: values.satelliteCode,
        respondentCode: values.respondentCode,
        technician: values.technician,
        firstName: values.firstName,
        lastName: values.lastName,
        streetAddress: values.streetAddress,
        emailAddress: values.emailAddress,
        city: values.city,
        state: values.state,
        postalCode: values.postalCode,
        phoneNumber: values.phoneNumber,
        subscribe: values.subscribe,
        source: values.source,
        serviceId: values.serviceId,
        version: values.version,
        technicianId: values.technicianId,
        isFromSMS: location.pathname.toLowerCase().includes('sms'),
        membershipType: values.membershipType,
        jobType: values.jobType
      });
      setToken(response.token);
      if (location.pathname.toLowerCase().includes('sms')) {
        navigate("/thankyou");
      } else {
        navigate("/survey");
      }
    } catch (error) {
      if (error instanceof HttpRequestError) {
        swal.fire({
          text: error.message || "Unexpected error. Please try again.",
          toast: true,
          icon: "error",
          position: "top-right",
          timer: 3000,
        });
      }
    }
    finally {
      setIsLoading(false);
    }
  };

  const initialValues = {
    code: "",
    satelliteCode: "",
    respondentCode: "",
    technician: "",
    firstName: "",
    lastName: "",
    streetAddress: "",
    city: "",
    state: 0,
    postalCode: "",
    phoneNumber: "",
    emailAddress: "",
    subscribe: false,
    source: "",
    serviceId: 0,
    technicianId: null,
    membershipType: null,
    jobType: null
  };

  return (
    <>
      <div className="container flex-col justify-center mx-auto my-0 mb-20 md:max-w-2xl">
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ errors }) => (
            <Form className="px-5 py-5 bg-white rounded-lg shadow md:px-10 mb-9">
              {states && <FormHandler states={states} setLoading={setLoading} />}
              {
                !loading ?
                  <>
                    <div className="my-5">
                      <>
                        <div className="flex">
                          <label className="flex" htmlFor="code">
                            Code*
                          </label>
                          <p
                            onClick={displayHelp}
                            className="cursor-pointer text-sky-600 hover:text-sky-700"
                          >
                            need help?
                          </p>
                        </div>
                        <div>
                          <Field
                            type="numeric"
                            name="code"
                            placeholder="000"
                            maxLength="3"
                            onKeyPress={(event: any) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            className="w-3/12 p-3 mt-3 text-center border rounded-xl bg-gray-50"
                          />

                          <Field
                            name="satelliteCode"
                            placeholder="000"
                            className="w-3/12 p-3 mt-3 ml-2 text-center border rounded-xl bg-gray-50"
                          />

                          <Field
                            type="numeric"
                            name="respondentCode"
                            placeholder="00000"
                            // maxLength="5"
                            className="w-4/12 p-3 mt-3 ml-2 text-center border rounded-xl bg-gray-50"
                          />

                          <ErrorMessage
                            name="code"
                            component={() => (
                              <div className="flex items-end mt-2 ">
                                <ExclamationTriangleIcon
                                  className="mt-1 mr-2 text-red-500"
                                  width="15"
                                  height="15"
                                />
                                <span className="text-sm text-red-500">
                                  {errors.code}
                                </span>
                              </div>
                            )}
                          />
                          <ErrorMessage
                            name="satelliteCode"
                            component={() => (
                              <div className="flex items-end mt-2 ">
                                <ExclamationTriangleIcon
                                  className="mt-1 mr-2 text-red-500"
                                  width="15"
                                  height="15"
                                />
                                <span className="text-sm text-red-500">
                                  {errors.satelliteCode}
                                </span>
                              </div>
                            )}
                          />
                        </div>
                      </>
                    </div>
                    <div className="my-5">
                      <label className="flex" htmlFor="technician">
                        Technician, Plumber Or Residential Sales Consultant Name
                      </label>
                      <Field
                        name="technician"
                        placeholder="Consultant Name"
                        type="text"
                        className="w-full p-3 mt-3 border rounded-xl bg-gray-50"
                      />
                      <ErrorMessage
                        name="technician"
                        component={() => (
                          <div className="flex items-end mt-2 ">
                            <ExclamationTriangleIcon
                              className="mt-1 mr-2 text-red-500"
                              width="15"
                              height="15"
                            />
                            <span className="text-sm text-red-500">
                              {errors.technician}
                            </span>
                          </div>
                        )}
                      />
                    </div>
                    <div className="my-5">
                      <label className="flex" htmlFor="firstName">
                        First Name*
                      </label>
                      <Field
                        name="firstName"
                        placeholder="First Name"
                        type="text"
                        className="w-full p-3 mt-3 border rounded-xl bg-gray-50"
                      />
                      <ErrorMessage
                        name="firstName"
                        component={() => (
                          <div className="flex items-end mt-2 ">
                            <ExclamationTriangleIcon
                              className="mt-1 mr-2 text-red-500"
                              width="15"
                              height="15"
                            />
                            <span className="text-sm text-red-500">
                              {errors.firstName}
                            </span>
                          </div>
                        )}
                      />
                    </div>
                    <div className="my-5">
                      <label className="flex" htmlFor="lastName">
                        Last Name*
                      </label>
                      <Field
                        name="lastName"
                        placeholder="Last Name"
                        type="text"
                        className="w-full p-3 mt-3 border rounded-xl bg-gray-50"
                      />
                      <ErrorMessage
                        name="lastName"
                        component={() => (
                          <div className="flex items-end mt-2 ">
                            <ExclamationTriangleIcon
                              className="mt-1 mr-2 text-red-500"
                              width="15"
                              height="15"
                            />
                            <span className="text-sm text-red-500">
                              {errors.lastName}
                            </span>
                          </div>
                        )}
                      />
                    </div>
                    <div className="my-5">
                      <label className="flex" htmlFor="streetAddress">
                        Street Address*
                      </label>
                      <Field
                        name="streetAddress"
                        placeholder="Street Address"
                        type="text"
                        className="w-full p-3 mt-3 border rounded-xl bg-gray-50"
                      />
                      <ErrorMessage
                        name="streetAddress"
                        component={() => (
                          <div className="flex items-end mt-2 ">
                            <ExclamationTriangleIcon
                              className="mt-1 mr-2 text-red-500"
                              width="15"
                              height="15"
                            />
                            <span className="text-sm text-red-500">
                              {errors.streetAddress}
                            </span>
                          </div>
                        )}
                      />
                    </div>
                    <div className="my-5">
                      <label className="flex" htmlFor="city">
                        City*
                      </label>
                      <Field
                        name="city"
                        placeholder="City"
                        type="text"
                        className="w-full p-3 mt-3 border rounded-xl bg-gray-50"
                      />
                      <ErrorMessage
                        name="city"
                        component={() => (
                          <div className="flex items-end mt-2 ">
                            <ExclamationTriangleIcon
                              className="mt-1 mr-2 text-red-500"
                              width="15"
                              height="15"
                            />
                            <span className="text-sm text-red-500">
                              {errors.city}
                            </span>
                          </div>
                        )}
                      />
                    </div>
                    <div className="my-5">
                      <label className="flex" htmlFor="state">
                        State/Province*
                      </label>
                      <Field
                        className="w-full p-3 mt-3 border rounded-xl bg-gray-50"
                        as="select"
                        name="state"
                      >
                        <option value={0}>--Select One---</option>
                        {states?.map((state) => (
                          <option key={state.value} value={state.value}>
                            {state.text}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <ErrorMessage
                      name="state"
                      component={() => (
                        <div className="flex items-end mt-2 ">
                          <ExclamationTriangleIcon
                            className="mt-1 mr-2 text-red-500"
                            width="15"
                            height="15"
                          />
                          <span className="text-sm text-red-500">{errors.state}</span>
                        </div>
                      )}
                    />
                    <div className="my-5">
                      <label className="flex" htmlFor="postalCode">
                        Postal Code*
                      </label>
                      <Field
                        name="postalCode"
                        placeholder="Postal Code"
                        type="text"
                        className="w-full p-3 mt-3 border rounded-xl bg-gray-50"
                      />
                      <ErrorMessage
                        name="postalCode"
                        component={() => (
                          <div className="flex items-end mt-2 ">
                            <ExclamationTriangleIcon
                              className="mt-1 mr-2 text-red-500"
                              width="15"
                              height="15"
                            />
                            <span className="text-sm text-red-500">
                              {errors.postalCode}
                            </span>
                          </div>
                        )}
                      />
                    </div>
                    <div className="my-5">
                      <label className="flex" htmlFor="phoneNumber">
                        Phone Number*
                      </label>
                      <Field
                        name="phoneNumber"
                        placeholder="Phone Number"
                        type="tel"
                        onKeyPress={(event: any) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        className="w-full p-3 mt-3 border rounded-xl bg-gray-50"
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component={() => (
                          <div className="flex items-end mt-2 ">
                            <ExclamationTriangleIcon
                              className="mt-1 mr-2 text-red-500"
                              width="15"
                              height="15"
                            />
                            <p className="text-sm text-red-500">
                              {errors.phoneNumber}
                            </p>
                          </div>
                        )}
                      />
                    </div>

                    <div className="my-5">
                      <label className="flex" htmlFor="emailAddress">
                        Email Address*
                      </label>
                      <Field
                        name="emailAddress"
                        placeholder="Email"
                        type="email"
                        className="w-full p-3 mt-3 border rounded-xl bg-gray-50"
                      />
                      <ErrorMessage
                        name="emailAddress"
                        component={() => (
                          <div className="flex items-end mt-2 ">
                            <ExclamationTriangleIcon
                              className="mt-1 mr-2 text-red-500"
                              width="15"
                              height="15"
                            />
                            <p className="text-sm text-red-500">
                              {errors.emailAddress}
                            </p>
                          </div>
                        )}
                      />
                    </div>
                    <div className="flex my-5 ">
                      <label htmlFor="tips">
                        Yes, please email me money-saving tips and special offers!
                      </label>
                      <Field className="ml-1" name="subscribe" type="checkbox" />
                    </div>
                    {/* <input
                type="submit"
                value="Next"
                className="w-full py-3 font-bold text-white uppercase transition-colors rounded bg-sky-600 hover:cursor-pointer hover:bg-sky-700"
              /> */}
                    <LoadingButton
                      buttonText="Submit"
                      value="Next"
                      isLoading={isLoading}
                    />
                  </> : <div className="text-center"><div role="status">
                    <svg aria-hidden="true" className="inline w-20 h-20 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div></div>
              }
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ExpertsForm;
