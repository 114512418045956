import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import HomevestorsLayout from "../../../layouts/homevestors-layout";
import ServiceExpertsLayout from "../../../layouts/serviceexperts-layout";
import { HttpRequestError } from "../../../models/http-request-error";
import { HOMEVESTORS, SERVICEEXPERTS } from "../../../models/survey-company";
import { USER_TYPE_RESPONDENT } from "../../../models/user";
import { saveAnswer } from "../../../shared/services/respondent-survey-service";
import {
    getCurrentUser,
    isAuthenticated,
} from "../../../shared/services/session-service";
import {
    completeSurvey,
    getSurveyWithDetails,
} from "../../../shared/services/survey-service";
import { SurveyCountableItem, SurveyCountableItemHomevestors } from "../../../shared/SurveyCountableItem";
import { SurveyOpenItem, SurveyOpenItemHomevestors } from "../../../shared/SurveyOpenItem";

const Survey = () => {
    const [dataSource, setDataSource] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [homeVestorSubmitText, setHomeVestorSubmitText] = useState<string>('Continue');
    const spinnerDelay = 1000; // ms to wait before the spinner appears while isLoading

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (isLoading && isFormSubmitting) { // only show spinner on form submit and after delay
            timer = setTimeout(() => setShowSpinner(true), spinnerDelay);
        } else {
            setShowSpinner(false);
        }

        return () => clearTimeout(timer);
    }, [isLoading, isFormSubmitting]);

    const swal = withReactContent(Swal);
    const navigate = useNavigate();
    const user = getCurrentUser();
    useEffect(() => {
        async function getData() {
            try {
                const survey = await getSurveyWithDetails();
                setDataSource(survey);
            } catch (err) {
                if (err instanceof HttpRequestError) {
                    swal.fire({
                        text: err.message || "Unexpected error. Please try again.",
                        toast: true,
                        icon: "error",
                        position: "top-right",
                        timer: 3000,
                    });
                }
            }
        }
        getData();
    }, []);

    useEffect(() => {
        if (!isAuthenticated(USER_TYPE_RESPONDENT)) {
            if (user?.companyId === HOMEVESTORS) {
                navigate("/c/hv");
            } else if (user?.companyId === SERVICEEXPERTS) {
                navigate("/serviceexperts");
            } else {
                navigate("/");
            }
        }
    });

    const handleSubmit = async (values: any,
        actions: any) => {
        actions.setStatus(undefined);

        try {
            setIsFormSubmitting(true);
            setIsLoading(true);

            if (user?.companyId === HOMEVESTORS) {
                setHomeVestorSubmitText('Submitting your response');
            }

            await completeSurvey();
            navigate("/thankyou");
            setIsLoading(false); // Set loading state back to false
            setIsFormSubmitting(false);
        } catch (err) {
            setIsLoading(false); // Set loading state back to false
            setIsFormSubmitting(false);

            if (user?.companyId === HOMEVESTORS) {
                setHomeVestorSubmitText('Continue');
            }

            if (err instanceof HttpRequestError) {
                if (user?.companyId === HOMEVESTORS) {
                    if (err.message.includes("numeric questions")) {
                        err.message = "Questions 1 and 3 are required, please select a value between 1 and 10."; // TODO this shouldn't be hardcoded, must be updated if HVA questions change
                    }
                    actions.setStatus(err.message || "Unexpected error. Please try again.");
                }
                else {
                    swal.fire({
                        text: err.message || "Unexpected error. Please try again.",
                        toast: true,
                        icon: "error",
                        position: "top-right",
                        timer: 2000,
                    });
                }
            }
            else {
                if (user?.companyId === HOMEVESTORS) {
                    actions.setStatus("Unexpected error. Please try again.");
                }
                else {
                    swal.fire({
                        text: "Unexpected error. Please try again.",
                        toast: true,
                        icon: "error",
                        position: "top-right",
                        timer: 2000,
                    });
                }
            }
        }
    };

    const submitAnswer = async (surveyDetailId: number, answer: string, setLoading: boolean = true) => {
        if (setLoading) {
            setIsLoading(true); // Set loading state to true
        }

        try {
            await saveAnswer({
                answer: answer,
                surveyDetailId: surveyDetailId,
            });
            setIsLoading(false); // Set loading state back to false
        } catch (err) {
            setIsLoading(false); // Set loading state back to false
            if (err instanceof HttpRequestError) {
                if (user?.companyId === HOMEVESTORS) {
                    console.error(err.message || "Unexpected error. Please try again.");
                }
                else {
                    swal.fire({
                        text: err.message || "Unexpected error. Please try again.",
                        toast: true,
                        icon: "error",
                        position: "top-right",
                        timer: 2000,
                    });
                }
            }
        }
    };

    const innerSurveyServiceExperts = () => {
        if (!dataSource?.surveyDetails) {
            return <div>Loading form..</div>;
        }

        return (
            <div className="w-full h-full mt-5">
                <div className="p-6 mx-auto bg-white rounded-md mb-28 md:max-w-xl">
                    <div className="mb-8 text-xl font-bold text-center">
                        Customer Satisfaction Survey
                    </div>
                    <Formik
                        initialValues={{
                            ...dataSource?.surveyDetails.reduce((values: any, item: any) => {
                                values[item.surveyDetailId] = item.type === 1 ? "" : 0;
                                return values;
                            }, {}),
                        }}
                        onSubmit={handleSubmit}
                    >
                        {() => (
                            <Form>
                                {dataSource?.surveyDetails.map((item: any) => {
                                    switch (item.type) {
                                        case 1:
                                            return (
                                                <SurveyOpenItem
                                                    key={item.surveyDetailId}
                                                    id={item.surveyDetailId}
                                                    text={item.question}
                                                    onChange={(newValue) =>
                                                        submitAnswer(item.surveyDetailId, newValue)
                                                    }
                                                ></SurveyOpenItem>
                                            );
                                        case 2:
                                            const ratingData = {
                                                count: 10,
                                                leftLabel: "Very Unlikely",
                                                rightLabel: "Very Likely",
                                            };
                                            return (
                                                <div
                                                    key={item.surveyDetailId}
                                                    className="flex justify-center my-5 md:mb-5"
                                                >
                                                    <Field name={`{items: ${item.surveyDetailId}}`}>
                                                        {({ form: { setFieldValue } }: any) => (
                                                            <SurveyCountableItem
                                                                key={item.surveyDetailId}
                                                                id={item.surveyDetailId}
                                                                positiveCount={true}
                                                                count={ratingData.count}
                                                                text={item.question}
                                                                header={item.header}
                                                                leftLabel={ratingData.leftLabel}
                                                                rightLabel={ratingData.rightLabel}
                                                                isNA={item.isNA}
                                                                onChange={(newValue) =>
                                                                    submitAnswer(
                                                                        item.surveyDetailId,
                                                                        newValue.toString()
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                            );
                                    }
                                })}
                                <div className="flex justify-center my-5 mb-10 md:mb-8">
                                    <button
                                        type="submit"
                                        className={"w-full py-3 font-bold text-white uppercase transition-colors rounded bg-sky-600 hover:bg-sky-700" + (isLoading ? " bg-sky-700 cursor-wait opacity-10" : "")}
                                        disabled={isLoading}>
                                        Submit
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        );
    };

    const innerSurveyHomevestors = () => {
        if (!dataSource?.surveyDetails) {
            return <div className="loading-overlay">
                <div className="spinner"></div>
            </div>;
        }

        return (
            <>
                <h2 className="mb-4 text-[1.375rem] leading-8 font-bold text-black">
                    Customer Satisfaction Survey
                </h2>
                <Formik
                    initialValues={{
                        ...dataSource?.surveyDetails.reduce((values: any, item: any) => {
                            values[item.surveyDetailId] = item.type === 1 ? "" : 0;
                            return values;
                        }, {}),
                    }}
                    onSubmit={handleSubmit}
                >
                    {({ status }) => (
                        <Form className="survey-form">
                            {dataSource?.surveyDetails.map((item: any) => {
                                switch (item.type) {
                                    case 1:
                                        return (
                                            <div
                                                key={item.surveyDetailId}
                                                className="flex my-5 mb-8"
                                            >
                                                <SurveyOpenItemHomevestors
                                                    key={item.surveyDetailId}
                                                    id={item.surveyDetailId}
                                                    text={item.question}
                                                    onChange={(newValue) =>
                                                        submitAnswer(item.surveyDetailId, newValue, false)
                                                    }
                                                ></SurveyOpenItemHomevestors>
                                            </div>
                                        );
                                    case 2:
                                        const ratingData = {
                                            count: 10,
                                            leftLabel: "Very Unlikely",
                                            rightLabel: "Very Likely",
                                        };

                                        if (item.question.toLowerCase().includes("satisfied")) {
                                            ratingData.leftLabel = "Very Dissatisfied";
                                            ratingData.rightLabel = "Very Satisfied";
                                        }

                                        return (
                                            <div
                                                key={item.surveyDetailId}
                                                className="flex justify-center my-5 mb-8"
                                            >
                                                <Field name={`{items: ${item.surveyDetailId}}`}>
                                                    {({ form: { setFieldValue } }: any) => (
                                                        <SurveyCountableItemHomevestors
                                                            key={item.surveyDetailId}
                                                            id={item.surveyDetailId}
                                                            positiveCount={true}
                                                            count={ratingData.count}
                                                            text={item.question}
                                                            header={item.header}
                                                            leftLabel={ratingData.leftLabel}
                                                            rightLabel={ratingData.rightLabel}
                                                            isNA={item.isNA}
                                                            onChange={(newValue) =>
                                                                submitAnswer(
                                                                    item.surveyDetailId,
                                                                    newValue.toString(),
                                                                    false
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        );
                                }
                            })}

                            {status &&
                                <span className="block -mx-4 sm:-mx-14 px-4 sm:px-14 my-4 p-2.5 bg-[#E72113] text-white text-xs uppercase text-center">
                                    {status}
                                </span>
                            }

                            <div className="flex justify-center">
                                <button
                                    type="submit"
                                    className={"py-3 font-bold text-white text-xl leading-6 transition-colors rounded bg-[#2089C4] hover:bg-sky-700 focus:bg-sky-700" + (isLoading ? " bg-sky-700 cursor-wait opacity-10" : "")}
                                    disabled={isLoading}>
                                    {homeVestorSubmitText}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>

                {showSpinner && (
                    <div className="loading-overlay">
                        <div className="spinner"></div>
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            {user?.companyId === HOMEVESTORS && (
                <HomevestorsLayout>{innerSurveyHomevestors()}</HomevestorsLayout>
            )}
            {user?.companyId === SERVICEEXPERTS && (
                <ServiceExpertsLayout>{innerSurveyServiceExperts()}</ServiceExpertsLayout>
            )}
        </>
    );
};

export default Survey;
