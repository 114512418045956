import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HttpRequestError } from "../../models/http-request-error";
import { Option } from "../../models/option";
import { logout, setToken } from "../../shared/services/session-service";
import { getStates } from "../../shared/services/state";
import { authenticateHomevestors } from "../../shared/services/survey-service";
import { postVisitorTracking } from "../../shared/services/visitor-tracking-service";
import hvErrorHouse from "src/images/homevestors/error-house.svg";
import './HvForm.css';

interface FormFieldProps {
    name: string;
    label: string;
    type?: string;
    placeholder: string;
    inputClasses: string;
    errors: { [key: string]: string };
}

const FormFieldError: React.FC<{ name: string; message: string; }> = ({ name, message }) => {
    return (
        <ErrorMessage name={name} component={() => (
            <div className="ml-2 mt-6">
                {/*<ExclamationTriangleIcon className="mt-1 mr-2 text-red-500" width="15" height="15" />*/}
                <p className="text-sm font-bold text-[#E72113]">{message}</p>
            </div>
        )} />
    );
}

const FormField: React.FC<FormFieldProps> = ({ name, label, type = "text", placeholder, inputClasses, errors }) => (
    <div className="my-4">
        <label className="flex mb-2" htmlFor={name}>
            {label}*
        </label>
        <Field name={name} placeholder={placeholder} type={type} className={`${inputClasses} ${errors[name] ? "border-[#E72113]" : ""}`} />
        <FormFieldError name={name} message={errors[name]} />
    </div>
);

const HvForm = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [satelliteCode, setSatelliteCode] = useState<string | null>();
    const [respondentCode, setRespondentCode] = useState<string | null>();
    const [states, setStates] = useState<Option<number>[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const spinnerDelay = 1000; // ms to wait before the spinner appears while isLoading

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (isLoading) {
            timer = setTimeout(() => setShowSpinner(true), spinnerDelay);
        } else {
            setShowSpinner(false);
        }

        return () => clearTimeout(timer);
    }, [isLoading]);

    const fetchStates = async () => {
        const states = await getStates();
        setStates(states);
    };

    const sendVisitorTracking = async () => {
        await postVisitorTracking({
            companyId: 31,
            pageName: 'Customer Information',
            satelliteCode: searchParams.get('scode') ?? undefined
        });
    };

    useEffect(() => {
        logout();
        setSatelliteCode(searchParams.get('scode'));
        setRespondentCode(searchParams.get('rcode'));
        fetchStates();
        sendVisitorTracking();
    }, []);

    const fieldDefinitions: {
        [key: string]: {
            errorMessage: string;
            required: boolean;
        };
    } = {
        firstName: {
            errorMessage: 'Please enter your first name.',
            required: true
        },
        lastName: {
            errorMessage: 'Please enter your last name.',
            required: true
        },
        emailAddress: {
            errorMessage: 'Please enter a valid email address.',
            required: true
        },
        city: {
            errorMessage: 'Please provide a valid city name.',
            required: true
        },
        state: {
            errorMessage: 'Please select a state.',
            required: true
        },
    };
    const initialValues = {
        lastName: "",
        firstName: "",
        emailAddress: "",
        city: "",
        state: ""
    };

    const handleSubmit = async (values: any,
        actions: any) => {
        actions.setStatus(undefined);

        // pre-submit validation
        let stopSubmit: boolean = false;
        Object.keys(fieldDefinitions).forEach(key => {
            const field = fieldDefinitions[key];
            if (field.required && (!values[key] || values[key].trim() === '')) {
                stopSubmit = true;

                const { errorMessage } = field;
                actions.setFieldError(key, errorMessage);
            }
        });

        // continue
        setIsLoading(true);

        try {
            if (satelliteCode && respondentCode) {
                const response = await authenticateHomevestors({
                    satelliteCode,
                    respondentCode,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    emailAddress: values.emailAddress,
                    city: values.city,
                    state: parseInt(values.state),
                    source: searchParams.get('source') ?? ''
                });
                setToken(response.token);
                navigate("/survey");
            }
        } catch (error) {
            if (error instanceof HttpRequestError) {
                let errorField: string | null = null;
                for (const key of Object.keys(fieldDefinitions)) {
                    const regex = new RegExp(key, 'i');
                    if (regex.test(error.message)) {
                        errorField = key;
                        break;
                    }
                }

                if (errorField) {
                    const { errorMessage } = fieldDefinitions[errorField];
                    actions.setFieldError(errorField, errorMessage);
                }
                else {
                    actions.setStatus("Unexpected error. You may see this message if you've already completed the survey.");
                }
            }
            else {
                actions.setStatus("Unexpected error. Please try again.");
            }
        } finally {
            setIsLoading(false);
        }
    };

    const inputClasses = "w-full p-2.5 py-3 border rounded bg-[#F2F5F7] font-light focus:border-[#2089C4] focus-within:bg-white focus:text-black focus:font-normal";
    const formFields = [
        { name: "firstName", label: "First Name", placeholder: "First Name" },
        { name: "lastName", label: "Last Name", placeholder: "Last Name" },
        { name: "emailAddress", label: "Email", placeholder: "Email" },
        { name: "city", label: "City", placeholder: "City" },
    ];

    return (
        <>
            {(!satelliteCode || !respondentCode) && (
                <>
                    <div className="mb-7">
                        <img src={hvErrorHouse} alt="Sad house emoji" className="mx-auto w-24" width="100" height="105" />
                    </div>
                    <h1 className="mb-4 text-[1.375rem] leading-8 font-bold text-black text-center">
                        Oops! Something went wrong.
                    </h1>
                    <p className="mb-4 text-black text-center">
                        The link you received is not correct.
                    </p>
                </>
            )}

            {(satelliteCode && respondentCode) && (
                <>
                    <h1 className="mb-4 text-[1.375rem] leading-8 font-bold text-black hva-form-title">
                        Your feedback matters.
                    </h1>
                    <p className="mb-2 text-black">
                        HomeVestors<sup>&reg;</sup>, the We Buy Ugly Houses<sup>&reg;</sup> people, appreciates hearing from their sellers.
                    </p>
                    <p className="mb-2 text-black">
                        To ensure we have the most accurate information in our records, please complete the following form.
                    </p>
                    <p className="mt-6 -mb-2 text-[#787372]">
                        * All fields are required
                    </p>
                    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                        {({ errors, status }) => (
                            <Form>
                                {formFields.map(field => (
                                    <FormField key={field.name} {...field} inputClasses={inputClasses} errors={errors} />
                                ))}
                                <div className="my-4">
                                    <label className="flex" htmlFor="state">
                                        State*
                                    </label>
                                    <Field className={`${inputClasses} ${errors['state'] ? "border-[#E72113]" : ""}`} as="select" name="state">
                                        <option value="0">---Select One---</option>
                                        {states?.map((state: any) => (
                                            <option key={state.value} value={state.value}>
                                                {state.text}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="state" component={() => (
                                        <div className="ml-2 mt-2">
                                            {/*<ExclamationTriangleIcon className="mt-1 mr-2 text-red-500" width="15" height="15" />*/}
                                            <p className="text-sm font-bold text-[#E72113]">{errors['state']}</p>
                                        </div>
                                    )} />

                                </div>

                                {status &&
                                    <span className="block -mx-4 sm:-mx-14 px-4 sm:px-14 my-4 p-2.5 bg-[#E72113] text-white text-xs uppercase text-center">
                                        {status}
                                    </span>
                                }

                                <div className="flex justify-center">
                                    <button
                                        type="submit"
                                        className={"py-3 font-bold text-white text-xl leading-6 transition-colors rounded bg-[#2089C4] hover:bg-sky-700 focus:bg-sky-700" + (isLoading ? " bg-sky-700 cursor-wait opacity-10" : "")}
                                        disabled={isLoading}>
                                        Continue
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>

                    {showSpinner && (
                        <div className="loading-overlay">
                            <div className="spinner"></div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default HvForm;
