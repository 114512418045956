import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getSurveyHtml} from "../shared/services/survey-service";
import {HttpRequestError} from "../models/http-request-error";

const SurveyHtml = () => {
    const [searchParams] = useSearchParams();
    const swal = withReactContent(Swal);
    const [html, setHtml] = useState<string>("Fetching data...")
    
    const renderHtml = async () =>{
        const respondentId = searchParams.get('respondentId');
        const companyId = searchParams.get('companyId');
        const satelliteId = searchParams.get('satelliteId');
        
        if(respondentId && companyId
        && satelliteId){
            
            try{
                const response = await getSurveyHtml(parseInt(respondentId),
                    parseInt(companyId), parseInt(satelliteId));
                setHtml(response);
            }catch (error) {
                if (error instanceof HttpRequestError) {
                    setHtml(error.message);
                    swal.fire({
                        text: error.message || "Unexpected error. Please try again.",
                        toast: true,
                        icon: "error",
                        position: "top-right",
                        timer: 3000,
                    });
                }
            }
        }else{
            setHtml('The url is invalid.');
        }
    }

    useEffect(() => {
        renderHtml();
    }, []);

    return (
        <>
            <div className="content" dangerouslySetInnerHTML={{__html: html}}></div> 
        </>
    );
};

export default SurveyHtml;