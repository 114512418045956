import { useState } from "react";
import scaleIcons from "src/images/surveydetail.png";
import scaleIconHappy from "src/images/homevestors/icon-happy-face.svg";
import scaleIconSad from "src/images/homevestors/icon-sad-face.svg";

export interface SurveyCountableItemProps {
    id: string;
    positiveCount: boolean;
    count: number;
    text: string;
    onChange(newValue: number): void;
    header?: string;
    leftLabel?: string;
    rightLabel?: string;
    isNA?: boolean;
}

const SurveyCountableItem = (props: SurveyCountableItemProps) => {
    const [active, setActive] = useState<number>();
    return (
        <div className={"w-full mb-4"}>
            {props.header && (
                <div className="mb-4 font-sans text-gray-700">{props.header}</div>
            )}
            <div className="mb-4 font-sans text-gray-700">{props.text}</div>
            <div className="flex items-center justify-center my-5">
                <img src={scaleIcons} alt="Rating legend" width="auto" />
            </div>

            <div className="flex flex-row justify-between">
                {Array<number>(props.count)
                    .fill(0)
                    .map((_, i) => i + 1)
                    .map((number) => {
                        return (
                            <div
                                key={number}
                                // style={{ background: active === number ? "red" : "white" }}
                                className={
                                    active === number
                                        ? "justify-center items-center text-white bg-blue-600 flex md:h-10 md:w-8 rounded-full h-7 w-6"
                                        : "flex md:h-10 md:w-8 h-7 w-6 rounded-full bg-slate-50 justify-center items-center cursor-pointer hover:bg-blue-600 hover:text-white"
                                }
                                onClick={() => {
                                    props.onChange(number);
                                    setActive(number);
                                }}
                            >
                                {number}
                            </div>
                        );
                    })}
                {props.isNA && (
                    <div
                        key={0}
                        className={
                            active === 0
                                ? "justify-center items-center text-white bg-blue-600 flex md:h-10 md:w-8 rounded-full h-7 w-6"
                                : "flex md:h-10 md:w-8 h-7 w-6 rounded-full bg-slate-50 justify-center items-center cursor-pointer hover:bg-blue-600 hover:text-white"
                        }
                        onClick={() => {
                            props.onChange(0);
                            setActive(0);

                        }}
                    >
                        <span>

                            N/A
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};


const processText = (text: string) => {
    // convert the (R) to superscript
    return text.replace(/\u00AE/g, '<sup>&reg;</sup>');
}

const SurveyCountableItemHomevestors = (props: SurveyCountableItemProps) => {
    const [active, setActive] = useState<number>();
    return (
        <div>
            {props.header && (
                <div className="mb-4 text-black font-bold">{props.header}</div>
            )}
            <p className="mb-4 text-black">
                <span dangerouslySetInnerHTML={{ __html: processText(props.text) }} />
                <br />
                <span className="font-bold text-sm">(Please note that 1 is the poorest rating, and 10 is the best.)</span>
            </p>
            <div className="flex justify-between my-1">
                <img src={scaleIconSad} alt="Sad face icon" className="w-6" />
                <img src={scaleIconHappy} alt="Happy face icon" className="w-6" />
            </div>
            <div className="flex justify-between text-sm font-bold mb-0.5">
                <span>{props.leftLabel}</span>
                <span>{props.rightLabel}</span>
            </div>

            <div className="flex justify-between">
                {Array<number>(props.count)
                    .fill(0)
                    .map((_, i) => i + 1)
                    .map((number) => {
                        return (
                            <div
                                key={number}
                                // style={{ background: active === number ? "red" : "white" }}
                                className={
                                    "flex sm:h-8 sm:w-8 h-6 w-6 text-sm sm:text-lg rounded-lg justify-center items-center font-bold " +
                                    (active === number
                                        ? "text-white bg-[#2089C4]"
                                        : "bg-[#F2F5F7] cursor-pointer hover:bg-[#2089C4] hover:text-white")
                                }
                                onClick={() => {
                                    props.onChange(number);
                                    setActive(number);
                                }}
                            >
                                {number}
                            </div>
                        );
                    })}
                {props.isNA && (
                    <div
                        key={0}
                        className={
                            "flex sm:h-8 sm:w-8 h-6 w-6 text-sm sm:text-lg rounded-lg justify-center items-center font-bold " +
                            (active === 0
                                ? "text-white bg-[#2089C4]"
                                : "bg-[#F2F5F7] cursor-pointer hover:bg-[#2089C4] hover:text-white")
                        }
                        onClick={() => {
                            props.onChange(0);
                            setActive(0);

                        }}
                    >
                        <span>

                            N/A
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export { SurveyCountableItem, SurveyCountableItemHomevestors };
export default SurveyCountableItem;
