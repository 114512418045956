import { Link } from "react-router-dom";
import expertslogo from "src/images/expertslogo.png";
import guaranteed from "src/images/guaranteed.png";
import { SurveyContext } from "src/pages/survey-context";
import styles from "../styles/ServiceExperts.module.css";
import {useEffect, useState} from "react";
import {getSubCenter} from "../shared/services/session-service";

const ServiceExpertsLayout = ({ children }: any) => {
  const [logo, setLogo]= useState<string>(expertslogo);  
  const updateSubCenter = (subCenter: string)=>{
    if(subCenter){
        setLogo(`https://cdn.imag-infra.net/sei/logos/${subCenter}.png`)
    }
  }
  
  useEffect(()=>{
     const subCenter = getSubCenter();
     if(subCenter){
         updateSubCenter(subCenter);
     }
  },[])
  
  return (
      <SurveyContext.Provider  value={
        {
          updateSubCenter: updateSubCenter
        }
      }>
      <div className={styles.expertsbg}>
        <div className="flex flex-wrap items-center justify-around mx-auto ">
          <img src={logo} alt="logo" />
          <div className="relative top-12 md:top-37">
            <img className="hidden md:block" src={guaranteed} alt="logo" />
          </div>
        </div>

        {children}
      </div>
      <footer className="fixed bottom-0 left-0 right-0 flex flex-col items-center justify-center px-3 py-2 text-sm text-gray-700 bg-white bg-opacity-75">
        <div className="flex flex-col items-center justify-center ">
          <div className="flex justify-center ">
            <Link
              to="https://www.serviceexperts.ca/privacy-policy"
              className="mr-2"
            >
              Privacy Policy
            </Link>
        
            <Link
              to="https://www.serviceexperts.ca/terms-of-use"
              className="ml-2"
            >
              Terms & Conditions
            </Link>
            &copy;{new Date().getFullYear()} Service Experts LLC. All Rights
            Reserved.
          </div>
        </div>
      </footer>
      </SurveyContext.Provider>
  );
};

export default ServiceExpertsLayout;
